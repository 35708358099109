<template>
  <div class="main-container">
    <h1 class="dark-text fs-18 fw-700" style="margin: 25px 0 10px">Institute Test</h1>
    <div class="row">
      <button
        class="filter-btn fw-600 fs-12"
        :class="{ 'filter-selected': isDraft === 0 }"
        @click="selectPublishTestType(0)"
      >
        Draft
      </button>
      <button
        class="filter-btn fw-600 fs-12"
        :class="{ 'filter-selected': isDraft === 1 }"
        @click="selectPublishTestType(1)"
      >
        Published
      </button>
      <button
        class="filter-btn fw-600 fs-12"
        :class="{ 'filter-selected': isDraft === 2 }"
        @click="selectPublishTestType(2)"
      >
        Completed
      </button>
    </div>
    <div class="row">
      <div class="col l3 m6 s12 pl-0 input-box">
        <input type="text" v-model="searchTestName" placeholder="By Test Name" />
      </div>
      <div class="col l3 m6 s12 pl-0 input-box">
        <select
          name=""
          id="select-input"
          class="browser-default pointer"
          @change="selectCourse($event)"
          v-model="selectedCourseId"
        >
          <option value="0" disabled selected>By Course</option>
          <option v-for="course in courseList" :key="course.courseId" :value="course.courseId">
            {{ course.courseName }}
          </option>
        </select>
      </div>
      <div class="col l3 m6 s12 pl-0 input-box">
        <ElTreeSelect
          node-key="id"
          v-model="selectedBatchesList"
          :data="batchOptions"
          multiple
          filterable
          clearable
          :multiple-limit="5"
          collapse-tags
          collapse-tags-tooltip
          class="w-100"
          :indent="10"
          :filter-node-method="filterBatchTree"
          :render-content="renderBatchTreeContent"
          @change="onBatchTreeChange"
        />
      </div>
      <div class="col l2 m6 s12 pl-0 input-box">
        <select
          class="browser-default pointer"
          id="select-input"
          @change="onSelectDuration($event)"
          v-model="selectedDurationId"
        >
          <option value="0" selected disabled>By Duration</option>
          <option v-for="duration in durations" :key="duration.value" :value="duration.value">
            {{ duration.text }}
          </option>
        </select>
      </div>
      <div class="col l1 s12 p-0 input-box">
        <button
          class="test-btn w-100"
          style="
            background: linear-gradient(90.2deg, #ff9421 0.16%, #fbad26 98.41%);
            padding: 9px 10px;
          "
          @click="clearFilters()"
          v-if="filterTriggered"
        >
          Clear
        </button>
        <button
          class="test-btn w-100"
          style="
            background: linear-gradient(90.2deg, #ff9421 0.16%, #fbad26 98.41%);
            padding: 9px 10px;
          "
          @click="applyFilters()"
          v-if="!filterTriggered"
        >
          Apply
        </button>
      </div>
    </div>

    <div v-if="preloadedTests && preloadedTests.length > 0">
      <div class="fs-14 fw-600" style="margin-bottom: 5px">Preloaded Tests</div>
      <div v-dragscroll class="preloaded-cards-container scroll-horizontal">
        <div v-for="preloadedTest in preloadedTests" :key="preloadedTest.InstituteTestId">
          <el-card
            class="preloaded-test-card box-card"
            :body-style="{ 'background-color': '#ffffff' }"
          >
            <template #header>
              <div class="card-header fs-14 fw-600 truncate">
                <span>{{ preloadedTest.Title }}</span>
              </div>
            </template>
            <div class="card-body">
              <div class="fs-13 fw-600 line-margin gray-text-clr flex items-center duration">
                <img
                  :src="require(`../../assets/time-passing 1.svg`)"
                  alt=""
                  style="margin-right: 6px"
                /><span class="block fw-400" style="width: 70px">Duration:</span>
                <span class="fw-500">{{ formatDuration(preloadedTest.Duration) }}</span>
              </div>
              <div class="fs-13 fw-600 gray-text-clr flex items-end">
                <img
                  :src="require(`../../assets/question 2.svg`)"
                  alt=""
                  style="margin-right: 6px"
                />
                <span class="block fw-400" style="width: 70px">Questions:</span>
                <span class="fw-500">{{ preloadedTest.QuestionCount }}</span>
              </div>
              <button
                class="preloaded-test-btn"
                style="
                  background: linear-gradient(90.2deg, #ff9421 0.16%, #fbad26 98.41%);
                  padding: 5px;
                "
                v-on:click="setSelectedPreloadedTest(preloadedTest)"
              >
                Publish
              </button>
            </div>
          </el-card>
        </div>
      </div>
    </div>
    <hr />
    <div
      class="scroll-verticle-bar"
      style="max-height: calc(100vh - 320px); margin-top: 20px"
      v-if="finalInstituteTestList.length > 0"
    >
      <div
        class="white test-list"
        v-for="(test, index) in finalInstituteTestList"
        :key="index"
        style="margin-bottom: 20px"
      >
        <h2 class="fs-14 fw-600 truncate black-text">
          <span :title="test.Title">{{ test.Title }}</span>
          <span
            class="gray-text-clr fs-12 fw-400"
            :title="'Question Type: ' + test.TestQuestionType"
          >
            ({{ test.TestQuestionType }})</span
          >
        </h2>
        <div class="row test-details flex items-center mb-0">
          <div class="col s12 m6 l3 p-0 column-1">
            <h2
              class="fs-13 fw-600 gray-text-clr line-margin flex items-center"
              :title="'Course: ' + test.CourseName"
            >
              <img
                :src="require(`../../assets/homework 1.svg`)"
                alt=""
                style="margin-right: 6px"
              /><span class="fw-500">{{ test.CourseName }}</span>
            </h2>
            <h2
              class="fs-13 fw-600 gray-text-clr flex items-end"
              :title="'Batch: ' + test.BatchNames"
            >
              <img
                :src="require(`../../assets/user-avatar 1.svg`)"
                alt=""
                style="margin-right: 6px"
              />
              <span class="fw-500 truncate" style="max-width: 200px">{{
                formatBatchName(test.BatchNames)
              }}</span>
            </h2>
          </div>
          <div class="col s12 m6 l3 p-0">
            <h2 class="fs-13 fw-600 line-margin gray-text-clr flex items-center duration">
              <img
                :src="require(`../../assets/time-passing 1.svg`)"
                alt=""
                style="margin-right: 6px"
              /><span class="block fw-400" style="width: 63px">Duration:</span>
              <span class="fw-500">{{ formatDuration(test.Duration) }}</span>
            </h2>
            <h2 class="fs-13 fw-600 gray-text-clr flex items-end">
              <img :src="require(`../../assets/question 2.svg`)" alt="" style="margin-right: 6px" />
              <span class="block fw-400" style="width: 63px">Question:</span>
              <span class="fw-500">{{ test.QuestionCount }}</span>
            </h2>
          </div>
          <div class="col s12 m6 l2 p-0 column-3">
            <h2
              class="fs-13 fw-600 gray-text-clr line-margin flex items-center"
              :title="`Start Date Time: ${formatDateTime(test.StartDateTime)}`"
            >
              <span
                class="material-icons material-symbols-outlined time-img fs-25"
                style="margin-right: 6px; color: rgb(109 194 112)"
                >play_circle</span
              >
              <span class="fw-500">{{ formatDateTime(test.StartDateTime) }}</span>
            </h2>
            <h2
              class="fs-13 fw-600 gray-text-clr flex items-center end-date"
              :title="`End Date Time: ${formatDateTime(test.EndDateTime)}`"
            >
              <span
                class="material-icons material-symbols-outlined time-img fs-25"
                style="margin-right: 6px; color: rgb(251 97 86 / 85%)"
                >stop_circle</span
              >
              <span class="fw-500">{{ formatDateTime(test.EndDateTime) }}</span>
            </h2>
          </div>
          <div class="col s12 m6 l4 p-0">
            <div class="flex flex-end items-center action-icons">
              <a
                class="tooltip"
                data-tooltip="I am a tooltip"
                style="height: 23px; margin-left: 16px"
                v-if="isDraft === 0"
                @click="publishInstituteTest(test.InstituteTestId)"
              >
                <span
                  class="material-icons material-symbols-outlined pointer"
                  style="color: #ff9800"
                  >publish</span
                >
                <span class="tooltiptext" style="left: -17px; width: auto">Publish</span>
              </a>
              <a
                class="tooltip"
                data-tooltip="I am a tooltip"
                style="height: 23px; margin-left: 16px"
                @click="viewInstituteTest(test.InstituteTestId, test.Title, test.StartDateTime)"
              >
                <span class="material-icons material-symbols-outlined pointer">visibility</span>
                <span class="tooltiptext" style="left: -10px">View</span>
              </a>
              <a
                class="tooltip"
                data-tooltip="I am a tooltip"
                style="margin-left: 16px; height: 23px"
                v-if="showEditBtn(test)"
                @click="editInstituteTest(test.InstituteTestId, test.IsDraft)"
              >
                <span
                  class="material-icons material-symbols-outlined pointer"
                  style="color: rgb(81 100 229 / 78%)"
                  >edit_square</span
                >
                <span class="tooltiptext" style="left: -6px">Edit</span>
              </a>
              <p
                class="flex fs-14 fw-600 light-gray-text tooltip pointer"
                style="margin-left: 16px"
                data-tooltip="I am a tooltip"
                @click="copyInstituteTest(test.InstituteTestId)"
              >
                <img :src="require(`../../assets/copy 3.svg`)" alt="" />
                <span class="tooltiptext">Copy</span>
              </p>
              <a
                class="tooltip"
                data-tooltip="I am a tooltip"
                style="margin-left: 16px; height: 23px"
                @click="downloadResult(test.InstituteTestId)"
                v-if="isDraft === 2"
              >
                <span
                  class="material-icons material-symbols-outlined pointer"
                  style="color: #16a085"
                  >download</span
                >
                <span class="tooltiptext" style="left: -42px; white-space: nowrap"
                  >Download Result</span
                >
              </a>
              <a
                class="tooltip"
                data-tooltip="I am a tooltip"
                style="margin-left: 16px; height: 23px"
                @click="goToTestAnalysisReport(test)"
                v-if="isDraft === 2"
              >
                <span
                  class="material-icons material-symbols-outlined"
                  style="color: rgb(81 100 229 / 78%)"
                  >send_time_extension</span
                >
                <span class="tooltiptext" style="left: -30px; white-space: nowrap"
                  >Test Analysis</span
                >
              </a>
              <a
                class="tooltip"
                data-tooltip="I am a tooltip"
                style="margin-left: 16px; height: 23px"
                @click="goToTestAnalysisQuesWise(test.InstituteTestId)"
                v-if="isDraft === 2"
              >
                <span
                  class="material-icons material-symbols-outlined"
                  style="color: rgb(81 100 229 / 78%)"
                  >assessment</span
                >
                <span class="tooltiptext" style="left: -30px; white-space: nowrap"
                  >Q.Wise Analysis</span
                >
              </a>
              <a
                class="tooltip"
                data-tooltip="I am a tooltip"
                style="margin-left: 16px; height: 23px"
                @click="goToMarksheet(test.InstituteTestId)"
                v-if="isDraft === 2"
              >
                <span
                  class="material-icons material-symbols-outlined"
                  style="color: rgb(45 187 102)"
                  >file_open</span
                >
                <span class="tooltiptext" style="left: -24px; white-space: nowrap">Marksheet</span>
              </a>
              <p
                class="flex fs-14 fw-600 light-gray-text tooltip pointer"
                style="margin-left: 16px"
                @click="
                  selectedInstituteTestId = test.InstituteTestId;
                  showDeleteModal = true;
                "
              >
                <img
                  :src="require(`../../assets/delete-new-icon.svg`)"
                  alt=""
                  style="width: 21px"
                />
                <span class="tooltiptext">Delete</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="text-center" style="margin-bottom: 20px">
      <img
        :src="require(`../../assets/no-result-found.png`)"
        alt=""
        style="width: 200px; opacity: 0.7"
      />
      <p class="fw-400 fs-15" style="margin-top: -20px; color: #8c8585">
        No test available for
        {{ this.searchTestName !== "" ? "searched test name" : "selected filter" }}.
      </p>
    </div>
    <div class="flex flex-between white pagination" v-if="finalInstituteTestList.length > 0">
      <p style="color: #333333">
        Showing <span>{{ instituteTestList.length !== 0 ? startPageNumber : 0 }}</span> -
        <span>{{ instituteTestList.length !== 0 ? endPageNumber : 0 }}</span> of
        <span>{{ totalQnsList }}</span> tests
      </p>
      <div class="flex flex-wrap" style="gap: 3px">
        <a
          class="navigation-btn"
          style="margin-right: 20px"
          :class="{ disable: startPageNumber === 1 }"
          @click="goToPrevPage(20)"
          title="Previous"
          ><span class="material-icons material-symbols-outlined fs-20 pointer">west</span></a
        >
        <a
          class="navigation-btn"
          @click="goToNextPage(20)"
          :class="{ disable: endPageNumber === totalQnsList || instituteTestList.length === 0 }"
          title="Next"
          ><span class="material-icons material-symbols-outlined fs-20 pointer">east</span></a
        >
      </div>
    </div>
    <Modal
      :show="showPreloadedTestModal"
      @close="
        showPreloadedTestModal = false;
        resetPublishModalValues();
      "
      :width="'80%'"
      :showHeader="true"
    >
      <template v-slot:header>
        <div class="row nmb">
          <div class="col s6 left-align" style="color: #642c90; margin: 10px 0">
            <p>Preloaded Test</p>
            <p>{{ this.selectedPreloadedTest.Title }}</p>
          </div>
          <div class="col s6 right-align VqTitle" style="color: #642c90; margin-top: 10px">
            <i
              class="material-icons clear_button red white-text pointer"
              @click="
                showPreloadedTestModal = false;
                resetPublishModalValues();
              "
              >clear</i
            >
          </div>
        </div>
      </template>
      <template v-slot:body>
        <div class="p-10">
          <div class="row">
            <div class="flex flex-start gap-20">
              <div class="flex flex-column" style="margin-top: 10px">
                <span class="fs-14 fw-700">Start DateTime</span>
                <v-date-picker
                  v-model="publishStartDateTime"
                  mode="dateTime"
                  :min-date="minDate"
                  :minute-increment="5"
                  is-required
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      type="text"
                      class="browser-default date-column"
                      style="border: 1px solid #e0e4f0 !important"
                      :value="inputValue"
                      v-on="inputEvents"
                      readonly
                    />
                  </template>
                </v-date-picker>
              </div>
              <div class="flex flex-column" style="margin-top: 10px">
                <span class="fs-14 fw-700">End DateTime</span>
                <v-date-picker
                  v-model="publishEndDateTime"
                  mode="dateTime"
                  :min-date="minDate"
                  :minute-increment="5"
                  is-required
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      type="text"
                      class="browser-default date-column"
                      style="border: 1px solid #e0e4f0 !important"
                      :value="inputValue"
                      v-on="inputEvents"
                      readonly
                    />
                  </template>
                </v-date-picker>
              </div>
            </div>
          </div>
          <div class="flex flex-column" style="margin-top: 10px">
            <div>
              <div>
                <span class="fs-14 fw-700">Batches <span class="red-text">*</span></span>
              </div>
              <div class="course-btn-container" v-if="this.batchListNew.length > 0">
                <div class="scroll-section">
                  <div v-for="(data, index1) in this.batchListNew" :key="index1" class="mt10">
                    <div class="flex flex-between items-center" style="padding-right: 20px">
                      <p class="subject-name fw-600" v-if="data.batches.length > 0">
                        {{ data.AffiliationName }}
                      </p>
                      <div class="select-all flex items-center" v-if="data.batches.length > 0">
                        <input
                          type="checkbox"
                          :id="index1 + 'chapter'"
                          class="pointer publish-input"
                          :checked="checkIsAllBatchSelected(index1, data.AffiliationId)"
                          @change="selectAllBatch($event, data, index1, data.AffiliationId)"
                          name="chapter"
                        />
                        <label :for="index1 + 'chapter'" class="pointer fw-400 fs-13 dark-text"
                          >Select All</label
                        >
                      </div>
                    </div>
                    <div class="flex mt-5" style="flex-wrap: wrap">
                      <button
                        class="course-button"
                        :class="{ selected: batchIdList.includes(topic) }"
                        v-for="(topic, index) in data.batches"
                        :key="index"
                        @click="onSelectBatch(topic, data.Name, index1)"
                      >
                        {{ topic.Name }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex flex-column" style="margin-top: 10px">
              <div class="flex items-center">
                <input
                  type="checkbox"
                  id="shuffle'"
                  class="pointer publish-input"
                  :checked="isShuffleQuestion === 1"
                  @change="shuffleQuestion($event)"
                  name="chapter"
                />
                <label
                  for="shuffle'"
                  class="pointer fs-14 fw-500"
                  style="color: #333333 !important; margin-left: 5px"
                  >Shuffle Questions</label
                >
              </div>
            </div>
            <span v-if="batchError === true" style="font-size: 11px; font-style: italic; color: red"
              >Please Select at least one batch</span
            >
            <div class="flex items-center" style="padding-top: 10px">
              <button class="publish-modal-button" @click="publishPreloadedTest()">Publish</button>
              <button
                class="cancel-publish-btn"
                style="font-size: 12px; font-weight: 600"
                @click="
                  showPreloadedTestModal = false;
                  resetPublishModalValues();
                "
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </template>
    </Modal>
    <Modal
      :show="showViewQuestionsModal"
      @close="showViewQuestionsModal = false"
      :width="'80%'"
      :showHeader="true"
      style="width: 100%"
    >
      <template v-slot:header>
        <div class="row nmb">
          <div class="col s6 left-align VqTitle" style="color: #642c90; margin-top: 45px">
            <p>Test Details :{{ this.viewTitle }}</p>
          </div>
          <div class="col s6 right-align VqTitle" style="color: #642c90; margin-top: 10px">
            <i
              class="material-icons clear_button red white-text pointer"
              @click="showViewQuestionsModal = false"
              >clear</i
            >
            <p>Test Start Date Time:{{ formatDateTime(this.ViewInstTestStartDate) }}</p>
          </div>
        </div>
      </template>
      <template v-slot:body>
        <div class="row">
          <div class="DownloadPdf col S3">
            <a class="pdfbtn waves-effect waves-light btn getclassbutton flex items-center">
              <i
                class="small material-icons text-darken-3"
                style="margin-left: 10px; margin-top: 5px; cursor: pointer; margin-right: 5px"
                >file_download</i
              >
              <span @click="PdfDownload((isAnswerReq = 0))">Export to Question PDF</span>
            </a>
          </div>
          <div class="DownloadPdfAndQuestion col S3">
            <a class="pdfbtn waves-effect waves-light btn getclassbutton flex items-center">
              <i
                class="small material-icons text-darken-3"
                style="margin-left: 10px; margin-top: 5px; cursor: pointer; margin-right: 5px"
                >file_download</i
              >
              <span @click="PdfDownload((isAnswerReq = 1))">Export to Question and Answer PDF</span>
            </a>
          </div>
        </div>
        <div class="row">
          <table class="qtl">
            <thead class="white-text" style="background: #642c90">
              <tr class="flex" style="padding: 15px 0">
                <td class="center col s1" style="white-space: nowrap">Question No</td>
                <td class="center col m2 s1">Part</td>
                <td class="center col m8 s9">Questions</td>
                <td class="center col s1">Solution</td>
              </tr>
            </thead>
            <tbody>
              <div v-for="(Questions, index) in questions" :key="index">
                <div class="row mb-0" style="margin-top: 20px">
                  <div class="center col s1">{{ index + 1 }}</div>
                  <div class="center col m2 s1 nowrap">{{ Questions.PartName }}</div>
                  <div
                    class="left col m8 s9"
                    style="border: 1px solid #e0e4f0; padding: 15px 10px; border-radius: 4px"
                    v-if="Questions.Q_LATEX_Status === 'Y'"
                  >
                    <vue-mathjax
                      :formula="Questions.Q_LATEX"
                      :options="VueMathJaxOptions"
                      :safe="false"
                      class="space-break fs-18 fw-500"
                    />
                    <div>
                      <img
                        class="col-sm-12 col-md-9"
                        v-if="Questions.DiagramUrl"
                        :src="getAzureUrl(getDiagramUrl(Questions.DiagramUrl))"
                        alt=""
                        :style="{
                          width: `${getDiagramWidth(Questions.DiagramUrl) * 100}%`,
                          height: `${getDiagramHeight(Questions.DiagramUrl) * 100}%`,
                        }"
                        draggable="false"
                      />
                    </div>

                    <div
                      v-if="
                        Questions.AnswerOption_A &&
                        Questions.AnswerOption_B &&
                        Questions.AnswerOption_C &&
                        Questions.AnswerOption_D &&
                        Questions.Q_LATEX_Status === 'Y'
                      "
                    >
                      <h1 class="fs-18 fw-600 dark-text m-0 mt-5 space-break">
                        <span v-if="!Questions.AnswerOption_A.includes('(A)')">(A)</span>
                        <vue-mathjax
                          :formula="Questions.AnswerOption_A"
                          :options="VueMathJaxOptions"
                          :safe="false"
                        />
                      </h1>
                      <h1 class="fs-18 fw-600 dark-text m-0 mt-5 space-break">
                        <span v-if="!Questions.AnswerOption_B.includes('(B)')">(B)</span>
                        <vue-mathjax
                          :formula="Questions.AnswerOption_B"
                          :options="VueMathJaxOptions"
                          :safe="false"
                        />
                      </h1>
                      <h1 class="fs-18 fw-600 dark-text m-0 mt-5 space-break">
                        <span v-if="!Questions.AnswerOption_C.includes('(C)')">(C)</span>
                        <vue-mathjax
                          :formula="Questions.AnswerOption_C"
                          :options="VueMathJaxOptions"
                          :safe="false"
                        />
                      </h1>
                      <h1 class="fs-18 fw-600 dark-text m-0 mt-5 space-break">
                        <span v-if="!Questions.AnswerOption_D.includes('(D)')">(D)</span>
                        <vue-mathjax
                          :formula="Questions.AnswerOption_D"
                          :options="VueMathJaxOptions"
                          :safe="false"
                        />
                      </h1>
                    </div>
                  </div>
                  <div
                    class="left col s8"
                    style="border: 1px solid #e0e4f0; border-radius: 4px"
                    v-else
                  >
                    <img
                      class="responsive-img"
                      draggable="false"
                      :src="getAzureUrl(Questions.QuestionDiagramURL)"
                    />
                  </div>
                  <div class="center col s1 icon">
                    <i
                      class="small material-icons icon"
                      style="color: #642c90; cursor: pointer"
                      @click="ViewSolution(Questions?.fullSolutionURL)"
                    >
                      lightbulb_outline</i
                    >
                  </div>
                </div>
              </div>
            </tbody>
          </table>
        </div>
      </template>
    </Modal>

    <Modal :show="showDeleteModal" :showHeader="false" @close="showDeleteModal = false">
      <template v-slot:body>
        <div class="warning-section-modal">
          <div class="flex flex-center" style="margin-bottom: 20px; padding-top: 30px">
            <img class="" :src="require(`../../assets/errorsvg.svg`)" style="width: 120px" />
          </div>
          <div class="flex flex-center" style="flex-direction: column">
            <span class="mesage-body fs-14 fw-500 dark-text" style="white-space: pre-line">
              Are you sure want to delete institute test?
            </span>
          </div>
          <div class="flex content-even" style="padding: 20px">
            <button class="confirm-btn fs-13 fw-600 ln-18" @click="deleteInstituteTest()">
              YES
            </button>
            <button class="cancel-btn fs-13 fw-600 ln-18" @click="showDeleteModal = false">
              NO
            </button>
          </div>
        </div>
      </template>
    </Modal>
    <Modal3 :show="showPublishModal" :showHeader="false" :showCloseBtn="false" :width="'80%'">
      <template v-slot:body>
        <div class="concept-modal">
          <div class="flex flex-between publish-head">
            <span class="fs-16 fw-700" style="margin-top: 2px">Publish Test</span>
            <div
              @click="
                showPublishModal = false;
                resetPublishModalValues();
              "
              style="cursor: pointer"
            >
              <span class="material-icons publish-close pointer" style="color: #ffffff !important"
                >close</span
              >
            </div>
          </div>
          <div class="modal-content">
            <div class="flex flex-between">
              <div class="flex flex-column">
                <span class="fs-14 fw-700">Test Name</span>
                <input
                  type="text"
                  class="date-column"
                  v-model="publishTitle"
                  placeholder="Test Name"
                  style="width: 637px !important"
                />
              </div>
            </div>
            <div class="flex flex-between">
              <div class="flex flex-column" style="margin-top: 10px">
                <span class="fs-14 fw-700">Start DateTime</span>
                <v-date-picker
                  v-model="publishStartDateTime"
                  mode="dateTime"
                  :min-date="minDate"
                  :minute-increment="5"
                  is-required
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      type="text"
                      class="browser-default date-column"
                      style="border: 1px solid #e0e4f0 !important"
                      :value="inputValue"
                      v-on="inputEvents"
                      readonly
                    />
                  </template>
                </v-date-picker>
              </div>
              <div class="flex flex-column" style="margin-top: 10px">
                <span class="fs-14 fw-700">End DateTime</span>
                <v-date-picker
                  v-model="publishEndDateTime"
                  mode="dateTime"
                  :min-date="minDate"
                  :minute-increment="5"
                  is-required
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      type="text"
                      class="browser-default date-column"
                      style="border: 1px solid #e0e4f0 !important"
                      :value="inputValue"
                      v-on="inputEvents"
                      readonly
                    />
                  </template>
                </v-date-picker>
              </div>
            </div>
            <div class="flex flex-column" style="margin-top: 10px">
              <span class="fs-14 fw-700 mb8">Duration <span class="red-text">*</span></span>
              <div class="flex flex-row mt8">
                <button
                  class="duration-button"
                  :class="{ selected: publishDurationId === duration.value }"
                  @click="selectDuration(duration.value)"
                  v-for="duration in durations"
                  :key="duration.value"
                  :value="duration.value"
                >
                  {{ duration.text }}
                </button>
              </div>
            </div>
            <div class="flex flex-column" style="margin-top: 10px">
              <div>
                <div>
                  <span class="fs-14 fw-700">Batches <span class="red-text">*</span></span>
                </div>
                <div class="course-btn-container" v-if="this.batchListNew.length > 0">
                  <div class="scroll-section">
                    <div v-for="(data, index1) in this.batchListNew" :key="index1" class="mt10">
                      <div class="flex flex-between items-center" style="padding-right: 20px">
                        <p class="subject-name" v-if="data.batches.length > 0">
                          {{ data.AffiliationName }}
                        </p>
                        <div class="select-all flex items-center" v-if="data.batches.length > 0">
                          <input
                            type="checkbox"
                            :id="index1 + 'chapter'"
                            class="pointer publish-input"
                            :checked="checkIsAllBatchSelected(index1, data.AffiliationId)"
                            @change="selectAllBatch($event, data, index1, data.AffiliationId)"
                            name="chapter"
                          />
                          <label :for="index1 + 'chapter'" class="pointer fw-400 fs-13 dark-text"
                            >Select All</label
                          >
                        </div>
                      </div>
                      <div class="flex mt10" style="flex-wrap: wrap">
                        <button
                          class="course-button"
                          :class="{ selected: batchIdList.includes(topic) }"
                          v-for="(topic, index) in data.batches"
                          :key="index"
                          @click="onSelectBatch(topic, data.Name, index1)"
                        >
                          {{ topic.Name }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex flex-column" style="margin-top: 10px">
                <div class="flex items-center">
                  <input
                    type="checkbox"
                    id="shuffle'"
                    class="pointer publish-input"
                    :checked="isShuffleQuestion === 1"
                    @change="shuffleQuestion($event)"
                    name="chapter"
                  />
                  <label
                    for="shuffle'"
                    class="pointer fs-14 fw-500"
                    style="color: #333333 !important; margin-left: 5px"
                    >Shuffle Questions</label
                  >
                </div>
              </div>
              <span
                v-if="batchError === true"
                style="font-size: 11px; font-style: italic; color: red"
                >Please Select at least one batch</span
              >
              <div class="flex items-center" style="padding-top: 10px">
                <button class="publish-modal-button" @click="publishTest()">Publish</button>
                <button
                  class="cancel-publish-btn"
                  style="font-size: 12px; font-weight: 600"
                  @click="
                    showPublishModal = false;
                    resetPublishModalValues();
                  "
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Modal3>
    <Modal :show="showSolutionModal" @close="showSolutionModal = false" :showHeader="false">
      <template v-slot:body>
        <div style="display: flex; justify-content: space-between">
          <div style="font-size: 30px; color: #642c90">
            <strong>Solution</strong>
          </div>
          <i
            class="material-icons pointer"
            style="
              width: 25px;
              height: 25px;
              color: white;
              background: #ff7675;
              cursor: pointer;
              margin-top: 20px;
              margin-bottom: 5px;
            "
            @click="showSolutionModal = false"
            >clear</i
          >
        </div>
        <div class="divider TestLine-divide"></div>
        <table>
          <tr>
            <td class="center col s12">
              <img
                class="responsive-img"
                draggable="false"
                :src="getAzureUrl(this.ViewSolutionUrl)"
                style="max-height: 450px"
              />
            </td>
          </tr>
        </table>
        <div class="row"></div>
      </template>
    </Modal>
  </div>
</template>

<script>
import moment from "moment";
import { useToast } from "vue-toastification";
import Papa from "papaparse";
import { ElTreeSelect } from "element-plus";
import Api from "../../Api";
import MobileApi from "../../Mobileapi";
import DataApi from "../../Dataapi";
import Modal from "../../components/Modal.vue";
import Modal3 from "../../components/Modal3.vue";

const toast = useToast();

export default {
  components: { ElTreeSelect, Modal, Modal3 },
  data() {
    return {
      isDraft: 0,
      affiliationId: null,
      instituteTestList: [],
      startPageNumber: 1,
      endPageNumber: 20,
      searchTestName: "",
      courseList: [],
      selectedCourseId: 0,
      batches: [],
      selectedBatches: [],
      selectedBatchId: [],
      durations: [
        {
          text: "30 min",
          value: 30,
        },
        {
          text: "45 min",
          value: 45,
        },
        {
          text: "60 min",
          value: 60,
        },
        {
          text: "1hr 30 min",
          value: 90,
        },
        {
          text: "2hrs",
          value: 120,
        },
        {
          text: "3hrs",
          value: 180,
        },
        {
          text: "3hr 20min",
          value: 200,
        },
      ],
      selectedDurationId: 0,
      filterTriggered: false,
      showDeleteModal: false,
      selectedInstituteTestId: null,
      viewTitle: "",
      ViewInstTestStartDate: "",
      showViewQuestionsModal: false,
      showPreloadedTestModal: false,
      selectedPreloadedTest: null,
      VueMathJaxOptions: {
        extensions: ["tex2jax.js"],
        showProcessingMessages: false,
        jax: ["input/TeX", "output/chtml"],
        lazyAlwaysTypeset: null,
        showMathMenu: false,
        tex2jax: {
          inlineMath: [
            ["$", "$"],
            ["\\(", "\\)"],
          ],
          displayMath: [
            ["$$", "$$"],
            ["\\[", "\\]"],
          ],
          processEscapes: false,
          processClass: "tex2jax_process",
        },
      },
      showPublishModal: false,
      publishTitle: "",
      publishStartDateTime: "",
      publishEndDateTime: "",
      publishDurationId: null,
      publishBatches: [],
      batchIdList: [],
      isShuffleQuestion: 0,
      batchError: false,
      ViewSolutionUrl: "",
      showSolutionModal: false,
      prevBtnTrigger: false,
      serverTime: "",
      totalQnsList: 0,
      pageNo: 1,
      batchOptions: [],
      selectedBatchesList: [],
      batchListNew: [],
      preloadedTests: [],
      minDate: moment().startOf("day").toDate(),
    };
  },
  beforeMount() {
    document.body.style.backgroundColor = "#F6F8FB";
  },
  computed: {
    finalInstituteTestList() {
      if (this.searchTestName !== "") {
        return this.instituteTestList.filter((test) =>
          test.Title.toLowerCase().includes(this.searchTestName.toLowerCase()),
        );
      }
      return this.instituteTestList;
    },
  },
  created() {
    if (this.$route.params.teacherDashCourseId != null) {
      this.selectedCourseId = Number(this.$route.params.teacherDashCourseId);
      this.affiliationId = Number.isNaN(Number(this.$route.params.teacherDashAffId))
        ? Number(this.$store.getters.user.AffiliationId)
        : Number(this.$route.params.teacherDashAffId);
      if (this.selectedCourseId > 0) {
        this.bindBatches();
        this.loadPreloadedTests();
      }
    }
    const user = this.$store.getters.user;
    this.affiliationId = parseInt(user.AffiliationId);
    if (Number(this.$route.params.isNavigate) === 1) {
      this.instituteTestListParams = JSON.parse(sessionStorage.getItem("instituteTestListParams"));
      this.selectedCourseId = this.instituteTestListParams.CourseId;
      this.selectedDurationId = this.instituteTestListParams.Duration;
      if (this.selectedDurationId !== 0 || this.selectedCourseId !== 0) {
        this.filterTriggered = true;
      }
      this.isDraft = this.instituteTestListParams.Type;
      this.bindBatches();
      this.getInstituteTestList();
      if (this.selectedCourseId > 0) {
        this.loadPreloadedTests();
      }
    }
    Api.getDataOfAffiliationCourses({}, (resp) => {
      this.courseList = resp.data;
    });
    MobileApi.getServerTime({}, (resp) => {
      this.serverTime = resp.data.datetime;
    });
    this.bindBatches();
    this.getInstituteTestList();
  },
  methods: {
    getAzureUrl(imageUrl) {
      if (imageUrl.startsWith("~")) {
        imageUrl = imageUrl.replace("~", process.env.VUE_APP_STORAGE_URL);
      }
      return imageUrl;
    },
    selectCourse(event) {
      this.selectedCourseId = Number(event.target.value);
      const obj = { isEditMode: false, courseId: this.selectedCourseId };
      MobileApi.getParentChildBatchesForAffiliation(obj, (response) => {
        this.batchOptions = response.data;
        this.instituteTestList = [];
        this.batches = [];
        this.selectedBatches = [];
        this.selectedBatchId = [];
        this.$route.params.isNavigate = null;
        this.startPageNumber = 1;
        this.endPageNumber = 20;
        this.PageNo = 1;
        this.filterTriggered = true;
        this.getInstituteTestList();
        this.bindBatches();
      });
      this.loadPreloadedTests();
    },
    loadPreloadedTests() {
      if (!this.selectedCourseId) {
        this.preloadedTests = [];
        return;
      }
      const data = {
        courseId: this.selectedCourseId,
        preloadedAffiliationId: process.env.VUE_APP_PRELOADED_AFFILIATION_ID,
      };
      DataApi.getPreloadedTestsForCourse(
        data,
        (response) => {
          this.preloadedTests = response;
        },
        (error) => {
          console.log(error);
          this.preloadedTests = [];
        },
      );
    },
    setSelectedPreloadedTest(preloadedTest) {
      if (!preloadedTest) {
        return;
      }
      this.selectedPreloadedTest = preloadedTest;
      this.showPreloadedTestModal = true;
      MobileApi.getBatches(
        {
          AffiliationId: this.affiliationId,
          CourseId: this.selectedPreloadedTest.CourseId,
        },
        (resp) => {
          this.publishBatches = resp.data;
          this.groupBatchesByAffiliation(this.publishBatches);
        },
      );
    },
    bindBatches() {
      this.batches = [];
      MobileApi.getBatches(
        {
          AffiliationId: this.affiliationId,
          CourseId: this.selectedCourseId,
        },
        (response) => {
          this.batches = response.data;
          this.publishBatches = response.data;
          if (Number(this.$route.params.isNavigate) === 1) {
            const selectedBatches = this.instituteTestListParams.BatchIds.split(",");
            this.selectedBatchId = selectedBatches;
            if (this.selectedBatchId.length > 0 && this.selectedBatchId[0] !== "") {
              this.autoBatchSelected(selectedBatches);
            }
          }
          if (this.$route.params.teacherDashBatchId > 0) {
            const batchId = Number(this.$route.params.teacherDashBatchId);
            const obj = { Id: batchId };
            this.onSelectedBatch(obj);
          }
        },
      );
    },
    renderBatchTreeContent(h, { data }) {
      let returnVal;
      if (!data.children) {
        returnVal = h("span", { style: { "font-weight": 600, color: "#626AEF" } }, data.label);
      } else {
        returnVal = h("span", `${data.label} (${data.children.length})`);
      }
      return returnVal;
    },
    filterBatchTree(value, data) {
      return data.label.toLowerCase().includes(value.toLowerCase());
    },
    onBatchTreeChange() {
      this.filterTriggered = false;
    },
    customLabel(option) {
      return `${option.Name}`;
    },
    onSelectedBatch(option) {
      const index = this.batches.findIndex((item) => item.Id === option.Id);
      this.batches[index].checked = true;
      this.selectedBatchId = [];
      this.selectedBatches = this.batches;
      this.filterTriggered = false;
      this.isFilterSelected();
      this.$route.params.isNavigate = null;
    },
    autoBatchSelected(option) {
      if (option.length > 0 && Number(this.$route.params.isNavigate) === 1) {
        option.forEach((item1) => {
          const index = this.batches.findIndex((item2) => item2.Id === Number(item1));
          if (index > -1) {
            this.batches[index].checked = true;
          }
        });
        const batches = this.batches.filter((item) => item.checked === true);
        this.selectedBatches = batches;
        this.filterTriggered = true;
      }
    },
    onRemoveBatch(option) {
      const index = this.batches.findIndex((item) => item.Id === option.Id);
      this.batches[index].checked = false;
      this.filterTriggered = false;
      this.selectedBatchId = [];
      this.isFilterSelected();
      this.$route.params.isNavigate = null;
    },
    deselectAllBatch() {
      for (let i = 0; i < this.batches.length; i += 1) {
        this.batches[i].checked = false;
      }
      this.selectedBatches = [];
      this.selectedBatchId = [];
      this.filterTriggered = false;
      this.isFilterSelected();
      this.$route.params.isNavigate = null;
    },
    isFilterSelected() {
      this.selectedBatchId = [];
      if (this.selectedBatches.length > 0) {
        this.selectedBatches.forEach((item) => {
          if (item.checked) {
            this.selectedBatchId.push(item.Id);
          }
        });
      }
      if (this.selectedCourseId !== 0 && this.selectedBatchId.length === 0) {
        this.filterTriggered = true;
        if (this.endPageNumber < 20) {
          this.endPageNumber = 20;
        }
        this.getInstituteTestList();
      }
    },
    onSelectDuration(event) {
      this.selectedDurationId = Number(event.target.value);
      this.filterTriggered = false;
      this.$route.params.isNavigate = null;
    },
    applyFilters() {
      this.startPageNumber = 1;
      this.endPageNumber = 20;
      this.pageNo = 1;
      if (this.selectedBatches.length > 0) {
        this.selectedBatchId = [];
        this.selectedBatches.forEach((item) => {
          if (item.checked) {
            this.selectedBatchId.push(item.Id);
          }
        });
      }
      if (
        this.selectedCourseId !== 0 ||
        this.selectedBatches.length > 0 ||
        this.selectedDurationId !== 0
      ) {
        this.filterTriggered = true;
        this.getInstituteTestList();
      }
    },
    clearFilters() {
      this.selectedCourseId = 0;
      this.selectedBatchId = [];
      this.selectedBatches = [];
      this.batches = [];
      this.selectedDurationId = 0;
      this.filterTriggered = false;
      this.searchTestName = "";
      this.startPageNumber = 1;
      this.lastPageNumber = 20;
      this.startPageNumber = 0;
      this.endPageNumber = 20;
      this.pageNo = 1;
      this.preloadedTests = [];
      this.$route.params.isNavigate = null;
      this.getInstituteTestList();
    },
    getInstituteTestList() {
      this.$store.dispatch("showLoader", true);
      let data;
      this.searchTestName = "";
      if (Number(this.$route.params.isNavigate) === 1) {
        const instituteTestListParams = JSON.parse(
          sessionStorage.getItem("instituteTestListParams"),
        );
        this.startPageNumber = instituteTestListParams.startPageNumber;
        this.endPageNumber = instituteTestListParams.endPageNumber;
        this.pageNo = instituteTestListParams.PageNo;
        data = {
          AffiliationId: instituteTestListParams.AffiliationId,
          CourseId: instituteTestListParams.CourseId,
          BatchIds: this.selectedBatchesList.length > 0 ? this.selectedBatchesList.join(",") : null,
          Duration: instituteTestListParams.Duration,
          Type: instituteTestListParams.Type,
          PageNo: this.pageNo,
          PaginationLength: 20,
        };
      } else {
        data = {
          AffiliationId: this.affiliationId,
          CourseId: this.selectedCourseId,
          BatchIds: this.selectedBatchesList.length > 0 ? this.selectedBatchesList.join(",") : null,
          Duration: this.selectedDurationId,
          Type: this.isDraft,
          PageNo: this.pageNo,
          PaginationLength: 20,
        };
      }
      MobileApi.getInstituteTestListActionsV1(data, (response) => {
        const jsonData = data;
        jsonData.startPageNumber = this.startPageNumber;
        jsonData.endPageNumber = this.endPageNumber;
        sessionStorage.setItem("instituteTestListParams", JSON.stringify(jsonData));
        if (response.responseCode === 500) {
          toast.error(`${response.data.info}`, {
            timeout: 2500,
          });
        }
        if (response.data.length > 0 && response.responseCode === 200) {
          this.instituteTestList = response.data;
          this.totalQnsList = this.instituteTestList[0].Total;
          if (response.data.length < 20 || response.data.length === this.totalQnsList) {
            this.endPageNumber = this.totalQnsList;
          }
          this.$store.dispatch("showLoader", false);
        } else {
          this.instituteTestList = [];
          this.totalQnsList = 0;
          this.$store.dispatch("showLoader", false);
        }
      });
    },
    showEditBtn(test) {
      if (this.isDraft === 0) {
        return true;
      }
      if (this.isDraft === 1) {
        if (test.StartDateTime >= this.serverTime) {
          return true;
        }
      }
      return false;
    },
    publishInstituteTest(instituteTestId) {
      this.selectedInstituteTestId = instituteTestId;
      this.$store.dispatch("showLoader", true);
      MobileApi.getInstituteTestSummary(
        {
          instituteTestId,
        },
        (response) => {
          this.$store.dispatch("showLoader", false);
          let NoOfQuestions = 0;
          let SelectedQuestions = 0;
          const testSectionDetails = response.data.testSectionDetails;
          testSectionDetails.forEach((item) => {
            NoOfQuestions += item.NoOfQuestions;
            SelectedQuestions += item.SelectedQuestions;
          });
          if (NoOfQuestions === SelectedQuestions) {
            this.batchIdList = [];
            this.publishStartDateTime = "";
            this.publishEndDateTime = "";
            this.showPublishModal = true;
            this.filteredbatchIdList = [];
            const publishInstituteTest = this.finalInstituteTestList.filter(
              (a) => a.InstituteTestId === instituteTestId,
            );
            this.publishTitle = publishInstituteTest[0].Title;
            this.selectedPublishCourseId = publishInstituteTest[0].CourseId;
            const tempBatchList = response.data.testDetails[0].Batches.split(",");
            /// this.batchIdList = this.batchIdList.map((item) => Number(item));
            this.publishDurationId = publishInstituteTest[0].Duration;
            this.publishStartDateTime = moment(
              publishInstituteTest[0].StartDateTime,
              "YYYY-MM-DD h:mma",
            ).toDate();
            this.publishEndDateTime = moment(
              publishInstituteTest[0].EndDateTime,
              "YYYY-MM-DD h:mma",
            ).toDate();
            MobileApi.getBatches(
              {
                AffiliationId: this.affiliationId,
                CourseId: this.selectedPublishCourseId,
              },
              (resp) => {
                this.publishBatches = resp.data;
                this.groupBatchesByAffiliation(this.publishBatches);
                // Iterate through the data array and extract batches based on Ids
                // eslint-disable-next-line no-restricted-syntax
                for (const item of this.batchListNew) {
                  const batches = item.batches.filter((batch) =>
                    tempBatchList.includes(String(batch.Id)),
                  );
                  this.batchIdList.push(...batches);
                }
              },
            );
          } else {
            toast.error("Selected Question count is not equal to total question.", {
              timeout: 2500,
            });
          }
        },
      );
    },
    selectDuration(duration) {
      this.publishDurationId = duration;
    },
    SelectBatch(data) {
      if (this.batchIdList.includes(data.Id)) {
        const index = this.batchIdList.indexOf(data.Id);
        this.batchIdList.splice(index, 1);
      } else {
        this.batchIdList.push(data.Id);
      }
    },
    selectAllBatches(event) {
      if (event.target.checked) {
        this.batchIdList = this.publishBatches.map((item) => item.Id);
      } else {
        this.batchIdList = [];
      }
    },
    shuffleQuestion(event) {
      if (event.target.checked) {
        this.isShuffleQuestion = 1;
      } else {
        this.isShuffleQuestion = 0;
      }
    },
    publishTest() {
      if (this.publishTitle.trim() === "") {
        toast.error("Please enter the test title", {
          timeout: 2000,
        });
        return;
      }
      if (this.batchIdList.length < 1) {
        this.batchError = true;
      } else {
        this.batchError = false;
        const data = {
          InstituteTestId: this.selectedInstituteTestId,
          StartDateTime: moment(this.publishStartDateTime).format("YYYY-MM-DD HH:mm:ss.SSS"),
          EndDateTime: moment(this.publishEndDateTime).format("YYYY-MM-DD HH:mm:ss.SSS"),
          Duration: this.publishDurationId,
          Title: this.publishTitle,
          BatchIds: this.batchIdList.map((a) => a.Id).join(","),
          ShuffleQuestions: this.isShuffleQuestion,
        };
        this.$store.dispatch("showLoader", true);
        MobileApi.PublishInstituteTest(data, (response) => {
          if (response.responseCode === 200) {
            this.$store.dispatch("showLoader", false);
            this.showPublishModal = false;
            this.getInstituteTestList();
            toast.success("Institute test published successfully", {
              timeout: 2000,
            });
          } else if (response.responseCode === 500) {
            this.$store.dispatch("showLoader", false);
            toast.error(`${response.data.errors[0]}`, {
              timeout: 2000,
            });
          }
        });
        this.resetPublishModalValues();
      }
    },
    publishPreloadedTest() {
      if (this.batchIdList.length === 0) {
        this.batchError = true;
      } else {
        this.batchError = false;
        const data = {
          preloadedTestId: this.selectedPreloadedTest.InstituteTestId,
          startDateTime: moment(this.publishStartDateTime).format("YYYY-MM-DD HH:mm:ss.SSS"),
          endDateTime: moment(this.publishEndDateTime).format("YYYY-MM-DD HH:mm:ss.SSS"),
          batchIds: this.batchIdList.map((a) => a.Id).join(","),
          shuffleQuestions: this.isShuffleQuestion,
        };
        this.$store.dispatch("showLoader", true);
        DataApi.publishPreloadedTest(data, (response) => {
          if (response) {
            this.$store.dispatch("showLoader", false);
            this.showPreloadedTestModal = false;
            this.getInstituteTestList();
            toast.success("Preloaded test published successfully", { timeout: 2000 });
          } else {
            this.$store.dispatch("showLoader", false);
            toast.error(`${response.data.errors[0]}`, { timeout: 2000 });
          }
        });
        this.resetPublishModalValues();
      }
    },
    resetPublishModalValues() {
      this.publishTitle = "";
      this.publishStartDateTime = "";
      this.publishEndDateTime = "";
      this.publishDurationId = null;
      this.publishBatches = [];
      this.batchIdList = [];
      this.isShuffleQuestion = 0;
      this.batchError = false;
    },
    viewInstituteTest(instituteTestId, title, startDate) {
      this.viewTitle = title;
      this.ViewInstTestStartDate = startDate;
      this.selectedInstituteTestId = Number(instituteTestId);
      this.$store.dispatch("showLoader", true);
      MobileApi.ViewInstituteTestQuestionsV1(
        {
          instituteTestId,
        },
        (response) => {
          this.$store.dispatch("showLoader", false);
          if (response.data.length > 0) {
            this.questions = response.data;
            this.showViewQuestionsModal = true;
          } else {
            toast.error("No question available.", {
              timeout: 2000,
            });
          }
        },
        () => {
          this.$store.dispatch("showLoader", false);
        },
      );
    },
    PdfDownload(IsAnswerReq) {
      const data = {
        instituteTestId: this.selectedInstituteTestId,
        isAnswerReq: IsAnswerReq,
      };
      MobileApi.PrintPage(data);
      this.$store.dispatch("showLoader", true);
      setTimeout(() => {
        this.$store.dispatch("showLoader", false);
      }, 3000);
    },
    ViewSolution(solutionUrl) {
      this.ViewSolutionUrl = this.getAzureUrl(solutionUrl);
      this.showSolutionModal = true;
    },
    editInstituteTest(instituteTestId, IsDraft) {
      this.$router.push({
        name: "EditInstituteTestNew",
        params: {
          testId: Number(instituteTestId),
          IsDraft,
        },
      });
    },
    copyInstituteTest(instituteTestId) {
      this.$route.params.isNavigate = null;
      this.$store.dispatch("showLoader", true);
      MobileApi.CopyInstituteTestv1(
        {
          instituteTestId,
        },
        (response) => {
          this.$store.dispatch("showLoader", false);
          if (response.errors.length) {
            toast.error(`${response.errors[0]}`, {
              timeout: 2000,
            });
          } else {
            this.getInstituteTestList();
            toast.success("Institute Test copied successfully!", {
              timeout: 2000,
            });
          }
        },
        () => {
          this.$store.dispatch("showLoader", false);
        },
      );
    },
    downloadResult(instituteTestId) {
      this.$store.dispatch("showLoader", true);
      Api.PartwiseInstituteTestReport(
        {
          insituteTestId: instituteTestId,
        },
        (response) => {
          this.$store.dispatch("showLoader", false);
          if (response.responseCode === 500) {
            toast.error(`${response.message}`, {
              timeout: 2000,
            });
          }
          this.exceldata = response.data;
          if (this.exceldata.length > 0 && response.responseCode === 200) {
            const blob = new Blob([Papa.unparse(this.exceldata)], {
              type: "text/csv;charset=utf-8;",
            });
            const link = document.createElement("a");
            const url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", "InstituteReport.csv");
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } else {
            toast.error("Result not available.", {
              timeout: 2000,
            });
          }
        },
      );
    },
    goToTestAnalysisReport(testDetails) {
      this.$router.push({
        name: "TestAnalysisReportNew",
        params: { testDetails: JSON.stringify(testDetails) },
      });
    },
    goToMarksheet(instituteTestId) {
      this.$router.push({
        name: "TestMarksheet",
        params: { instituteTestId },
      });
    },
    goToTestAnalysisQuesWise(instituteTestId) {
      this.$router.push({
        name: "TestAnalysisQuesWise",
        params: { instituteTestId },
      });
    },
    deleteInstituteTest() {
      this.$store.dispatch("showLoader", true);
      DataApi.deleteInstituteTest(
        {
          instituteTestId: this.selectedInstituteTestId,
        },
        (response) => {
          if (response) {
            this.getInstituteTestList();
            toast.success("Institute test deleted successfully.", { timeout: 2000 });
            this.showDeleteModal = false;
            this.$store.dispatch("showLoader", false);
          } else {
            toast.error("An error occured in deleting this test.", { timeout: 2000 });
            this.showDeleteModal = false;
            this.$store.dispatch("showLoader", false);
          }
        },
        (error) => {
          console.log(error);
          toast.error("You don't have access to delete this test.", { timeout: 2000 });
          this.showDeleteModal = false;
          this.$store.dispatch("showLoader", false);
        },
      );
    },
    formatDateTime(dateTime) {
      return moment(dateTime).utcOffset("-00:00").format("DD-MM-YYYY, hh:mm A");
    },
    formatDuration(duration) {
      if (duration === 90) {
        return "1 hr 30min";
      }
      if (duration === 120) {
        return "2 hrs";
      }
      if (duration === 180) {
        return "3 hrs";
      }
      return `${duration} min`;
    },
    formatBatchName(batches) {
      const batchList = batches.split(",");
      if (batchList.length === 1) {
        return batchList[0];
      }
      if (batchList.length === 2) {
        return `${batchList[0]}, ${batchList[1]}`;
      }
      return `${batchList[0]}, ${batchList[1]} ... +${batchList.length - 2}`;
    },
    selectPublishTestType(typeId) {
      this.isDraft = typeId;
      this.searchTestName = "";
      this.instituteTestList = [];
      this.selectedCourseId = 0;
      this.selectedBatches = [];
      this.selectedBatchId = [];
      this.preloadedTests = [];
      this.selectedDurationId = 0;
      this.$route.params.isNavigate = null;
      this.clearFilters();
    },
    goToNextPage() {
      this.$route.params.isNavigate = null;
      this.startPageNumber = this.endPageNumber + 1;
      this.endPageNumber += 20;
      this.pageNo += 1;
      this.getInstituteTestList();
    },
    goToPrevPage() {
      this.$route.params.isNavigate = null;
      this.endPageNumber = this.startPageNumber - 1;
      this.startPageNumber -= 20;
      this.pageNo -= 1;
      this.getInstituteTestList();
    },
    getDiagramUrl(diagramDetail) {
      const diagram = JSON.parse(diagramDetail);
      return diagram.diagram_0.url;
    },
    getDiagramWidth(diagramDetail) {
      const diagram = JSON.parse(diagramDetail);
      return diagram.diagram_0.position[2];
    },
    getDiagramHeight(diagramDetail) {
      const diagram = JSON.parse(diagramDetail);
      return diagram.diagram_0.position[3];
    },
    groupBatchesByAffiliation(chapterlist) {
      const batchListTemp = {};
      // eslint-disable-next-line no-restricted-syntax
      for (const chapter of chapterlist) {
        const affiliationId = chapter.AffiliationId;

        if (!batchListTemp[affiliationId]) {
          batchListTemp[affiliationId] = {
            AffiliationId: affiliationId,
            AffiliationName: chapter.AffiliationName,
            batches: [],
          };
        }
        batchListTemp[affiliationId].batches.push({
          Id: chapter.Id,
          Code: chapter.Code,
          Name: chapter.Name,
          AffiliationId: affiliationId,
        });
      }
      this.batchListNew = Object.values(batchListTemp);
    },
    checkIsAllBatchSelected(index, clusterId) {
      let returnData = false;
      const batchListTemp = this.batchIdList.filter((a) => a.AffiliationId === clusterId);
      const batchObj = this.batchListNew.filter((a) => a.AffiliationId === clusterId)[0];
      // ///this.tempClusterList = this.clusterListNew.filter((chapter) => batchListTemp.includes(chapter.Id));
      // const subjectChapters = this.tempClusterList.find((a) => a.CourseChapterId === clusterId);
      // const selectedChapters = this.selectedKscClusterIds.filter((a) => a.CourseChapterId === clusterId);
      if (
        batchListTemp != null &&
        batchObj.batchList != null &&
        batchObj.batchList.length > 0 &&
        batchListTemp.length > 0
      ) {
        if (batchObj.batchList.length === batchListTemp.length) {
          returnData = true;
        }
      }
      return returnData;
    },
    selectAllBatch(event, chapterData, index, subjectId) {
      if (event.target.checked) {
        const topicList = chapterData.batches.flat();
        topicList.forEach((item) => {
          if (!this.batchIdList.includes(item)) {
            this.batchIdList.push(item);
          }
        });
      } else {
        // eslint-disable-next-line no-plusplus, no-lonely-if
        if (this.batchIdList.length > 0) {
          // eslint-disable-next-line no-plusplus
          for (let i = this.batchIdList.length - 1; i >= 0; i--) {
            if (this.batchIdList[i].AffiliationId === subjectId) {
              this.batchIdList.splice(i, 1);
            }
          }
        }
      }
      this.checkIsAllBatchSelected(index, chapterData.AffiliationId);
    },
    onSelectBatch(selectedBatch, index) {
      this.chapterCountList = [];
      if (this.batchIdList.includes(selectedBatch)) {
        const index1 = this.batchIdList.indexOf(selectedBatch);
        this.batchIdList.splice(index1, 1);
      } else {
        this.batchIdList.push(selectedBatch);
      }
      this.checkIsAllBatchSelected(index, selectedBatch.AffiliationId);
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap");
@import "https://unpkg.com/vue-multiselect@2.0.2/dist/vue-multiselect.min.css";

* {
  font-family: "Open Sans", sans-serif;
  box-sizing: border-box !important;
}

/* custom css */
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

.dark-text {
  color: #333333;
}

.line-margin {
  margin: 7px 0;
}

.gray-text-clr {
  color: #333333;
}

.light-gray-text {
  color: #333333;
}

.mb-0 {
  margin-bottom: 0;
}

/* main css */
.main-container {
  margin-top: 20px;
  padding: 0 10px;
}

.filter-btn {
  background-color: transparent;
  border-radius: 5px;
  width: 95px;
  height: 28px;
  border: none;
  color: #adadad;
  cursor: pointer;
  margin-right: 20px;
  border: 2px solid #adadad;
  box-shadow: 4px 4px 10px rgb(100 44 144 / 6%);
}

.filter-btn.filter-selected {
  background-color: transparent;
  color: #3751ff;
  border: 2px solid #3751ff;
}

.test-list {
  box-shadow: 4px 4px 10px rgb(100 44 144 / 6%);
  border-radius: 6px;
  padding: 12px 17px;
}

.preloaded-test-btn,
.test-btn {
  font-weight: 600;
  font-size: 14px;
  color: white;
  border-radius: 5px;
  border: none;
  padding: 9px 13px;
  cursor: pointer;
}

input[type="text"] {
  font-size: 14px;
  border: 1px solid #e0e4f0 !important;
  background-color: white !important;
  height: 36px;
  border-radius: 4px;
  padding: 2px 10px;
  margin: 0;
}

input[type="text"]:not(.browser-default):focus:not([readonly]) {
  box-shadow: none;
}

select {
  border: 1px solid #e0e4f0 !important;
  background-color: white !important;
  height: 36px;
  border-radius: 4px;
  font-size: 14px;
  color: #adadad;
}

select:focus {
  outline: none;
}

#select-input {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: url("../../assets/Vector4.svg") no-repeat right;
  background-size: 38px 6px;
  height: 36px;
  padding-left: 7px;
}

select option:checked {
  color: #3751ff;
  font-weight: 600;
}

option:disabled {
  color: rgb(170, 170, 170) !important;
  font-weight: 500 !important;
}

select {
  overflow: hidden;
}

select::-webkit-scrollbar {
  width: 0;
}

select option {
  color: #333333 !important;
}

input::-webkit-input-placeholder {
  color: #adadad;
}

input::-moz-placeholder {
  color: #adadad;
}

input::-ms-input-placeholder {
  color: #adadad;
}

input::placeholder {
  color: #adadad;
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 27px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
  /* cursor: pointer; */
}

.tooltip {
  position: relative;
}

.tooltiptext {
  visibility: hidden;
  background-color: #3fbbb9;
  color: white;
  text-align: center;
  padding: 2px 8px;
  border-radius: 3px;
  z-index: 1;
  white-space: break-spaces;
  text-align: center;
  text-align: center;
  border-radius: 4px;
  font-size: 12px;
  transition: 0.3s all ease;
  font-weight: 600;
  position: absolute;
  left: -15px;
  bottom: -32px;
}

.tooltiptext::before {
  position: absolute;
  width: 10px;
  height: 10px;
  content: "";
  background: #3fbbb9;
  left: 42%;
  top: -5px;
  -webkit-backdrop-filter: #3fbbb9;
  backdrop-filter: #3fbbb9;
  transform: rotate(45deg);
  background: #3fbbb9;
  visibility: hidden;
  transition: 0.1s all ease;
  line-height: 20px;
}

.tooltip:hover .tooltiptext,
.tooltip:hover .tooltiptext::before {
  visibility: visible;
}

.pagination {
  padding: 10px 14px;
  align-items: center;
  border-radius: 5px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.navigation-btn {
  width: 30px;
  height: 30px;
  line-height: 38px;
  text-align: center;
  background-color: white;
  color: #8c8585;
  border: 1px solid #333333 !important;
  border-radius: 3px;
}

.navigation-btn .material-icons {
  color: #333333;
}

.navigation-btn.currentPage {
  border-color: #3751ff !important;
  color: #3751ff;
}

.navigation-btn.disable {
  pointer-events: none;
  border: 1px solid #8c8585 !important;
}

.navigation-btn.disable .material-icons {
  color: #8c8585;
}

.warning-section-modal {
  width: 400px;
  /* height: 356px; */
  background: #ffffff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.confirm-btn {
  width: 84px;
  height: 30px;
  background: #ffffff;
  border: 1px solid #37841c;
  border-radius: 4px;
  background: #ffffff;
  color: #37841c;
  cursor: pointer;
  margin-right: 10px;
}

.confirm-btn:hover {
  background: #37841c !important;
  color: #ffffff !important;
}

.cancel-btn {
  width: 84px;
  padding-right: 5px;
  padding-left: 5px;
  height: 30px;
  background: #ffffff;
  border: 1px solid #ff7675;
  border-radius: 4px;
  color: #ff7675;
  cursor: pointer;
  margin-left: 10px;
}

.cancel-btn:hover {
  background: #ff7675 !important;
  color: #ffffff !important;
}

.concept-modal {
  max-width: 1173px !important;
  min-width: 650px !important;
  width: 100% !important;
}

.publish-head {
  position: relative;
  width: 100%;
  background: #f3f5fc;
  padding: 10px;
  border-radius: 5px 5px 0 0;
}

.scroll-horizontal {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  gap: 10px;
  padding: 10px;
}

.scroll-horizontal::-webkit-scrollbar {
  height: 0px;
}

.scroll-horizontal::-webkit-scrollbar-track {
  background-color: #ececec;
  border-radius: 5px;
}

.scroll-horizontal::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 5px;
}

.preloaded-cards-container {
  width: 100%;
}

.preloaded-test-card {
  position: relative;
  width: 240px;
  background-color: #f3f5fc;
}

.preloaded-test-btn {
  display: none;
  position: absolute;
  bottom: 8px;
  right: 8px;
}

.preloaded-test-card .card-body:hover .preloaded-test-btn {
  display: block;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cursor-pointer {
  cursor: pointer;
}

.publish-close {
  background-color: #ff7675 !important;
  border: 1px solid #e0e4f0;
  border-radius: 3px;
}

.modal-content {
  padding: 10px;
}

.date-column {
  height: 39px !important;
  color: black !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  padding-left: 12px !important;
  background: #ffffff;
  border: 1px solid #e0e4f0 !important;
  border-radius: 4px !important;
  width: 310px !important;
}

.duration-button {
  height: 28px;
  border: 1px solid #e0e4f0;
  background: #f3f5fc;
  border-radius: 18px;
  padding-left: 15px;
  padding-right: 15px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  margin-right: 10px;
  margin-bottom: 10px;
  color: #333333;
  cursor: pointer;
}

.select-all {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e0e4f0;
  border-radius: 4px;
  background: #ffffff;
  width: 113px;
  height: 36px;
  gap: 5px;
}

.publish-input[type="radio"]:not(:checked),
.publish-input[type="radio"]:checked,
.publish-input[type="checkbox"]:not(:checked),
.publish-input[type="checkbox"]:checked {
  position: unset !important;
  opacity: 1 !important;
  pointer-events: all !important;
  width: 18px;
  height: 18px;
}

.publish-input[type="radio"]:checked,
.publish-input[type="radio"]:not(:checked) {
  position: unset !important;
  opacity: 1 !important;
  pointer-events: all !important;
}

.course-button {
  height: 28px;
  background: #f3f5fc;
  border: 1px solid #e0e4f0;
  border-radius: 18px;
  padding-left: 15px;
  padding-right: 15px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  margin-right: 10px;
  margin-bottom: 10px;
  color: #333333;
  cursor: pointer;
}

.publish-modal-button {
  width: 130px;
  height: 36px;
  background: #37841c;
  border-radius: 5px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  border: none;
  margin-right: 10px;
  cursor: pointer;
}

.cancel-publish-btn {
  width: 125px;
  height: 36px;
  border: 1px solid #ff7675;
  border-radius: 5px;
  color: #ff7675;
  line-height: 16px;
  text-transform: uppercase;
  background: white;
  cursor: pointer;
}

.selected {
  background: #3751ff !important;
  color: #ffffff !important;
}

.DownloadPdf,
.DownloadPdfAndQuestion {
  margin: 3px 0;
}

.pdfbtn {
  background: linear-gradient(90.2deg, #ff9421 0.16%, #fbad26 98.41%);
}

/* Media Query */
@media screen and (max-width: 992px) {
  .input-box {
    margin-bottom: 10px;
  }

  .input-box:nth-child(2),
  .input-box:nth-child(4) {
    padding-right: 0 !important;
  }

  .test-list {
    display: block;
  }

  .action-icons {
    justify-content: flex-start;
    margin-top: 7px;
  }

  .action-icons .tooltip:nth-child(1) {
    margin-left: 0 !important;
  }

  .column-3 {
    clear: left;
  }

  .column-3 h2:nth-child(1) {
    margin-top: 7px;
  }

  .pagination {
    margin-bottom: 10px;
  }

  .test-details {
    display: block !important;
  }

  .end-date {
    margin-top: 0 !important;
  }
}

@media screen and (max-width: 600px) {
  .input-box {
    padding-right: 0 !important;
  }

  .warning-section-modal {
    width: 300px;
  }

  .duration {
    margin-bottom: 0;
  }

  .course-btn-container {
    max-height: 300px;
    overflow-y: auto;
  }
}
</style>
